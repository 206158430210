import * as React from "react"
import ReactDOM from "react-dom"

import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"

interface Props {
  indexEl: HTMLElement
  isVisible: boolean
  children: string
}
const Anchor: React.FC<Props> = ({ children, indexEl, isVisible }) => {
  const anchorName = `a_${children.replace(/[^0-9]/g, "")}`

  return (
    <Wrapper id={anchorName}>
      {children}
      {indexEl &&
        ReactDOM.createPortal(
          <Item isVisible={isVisible}>
            <AnchorLink to={`/profile#${anchorName}`}>
              {children.replace(/^([0-9]{4}).+/, "$1")}
            </AnchorLink>
          </Item>,
          indexEl
        )}
    </Wrapper>
  )
}

export default Anchor

const Wrapper = styled.h4``

interface ItemProps {
  isVisible: boolean
}

const Item = styled.li<ItemProps>`
  background-color: ${props => (props.isVisible ? "#ddd" : "transparent")};
  transition: background-color 0.3s ease-in;
`
