import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import { MEDIA_MOBILE } from "../const"
import Metadata from "../components/metadata"
import Layout from "../components/layout"
import Heading2 from "../components/heading2"
import Year from "../components/biography/year"
import typography from "../utils/typography"

import { ProfileQuery } from "../../types/graphql-types"

interface ReleaseProps {
  link?: string
  children: string
}
const Release: React.FC<ReleaseProps> = ({ link, children }) => {
  return (
    <ReleaseItem>
      𝄞 {children}
      {link && (
        <a href={link} target="_blank" rel="noreferrer">
          配信・販売中
        </a>
      )}
    </ReleaseItem>
  )
}

interface ProfileProps {
  data: ProfileQuery
  location: Location
}
const Profile: React.FC<ProfileProps> = ({ data, location }) => {
  const [indexRef, setIndexRef] = useState(null)

  return (
    <Layout>
      <Metadata
        title="Profile"
        pagePath={location.pathname}
        description="ぁゃぴのProfile、Biography"
      />
      <Wrapper>
        <Heading2>Profile</Heading2>
        <ProfileWrapper>
          <ProfilePhoto>
            <Img fluid={data.photo.childImageSharp.fluid} alt="ぁゃぴ" />
          </ProfilePhoto>
          <ProfileContent>
            <h3>ぁゃぴ</h3>
            <p>
              誕生日：1985-06-23
              <br />
              身長：159cm
              <br />
              体重：49kg
              <br />
              血液型：A
              <br />
              趣味：自作PC
            </p>
          </ProfileContent>
        </ProfileWrapper>

        <Warning>読み終わるまで約15分かかります</Warning>

        <BiographyWrapper>
          <BiographyIndex ref={newRef => setIndexRef(newRef)} />

          <BiographyContent>
            <Year indexEl={indexRef} label="1985年(0歳)">
              <p>大阪府東大阪市で生まれる</p>
            </Year>

            <Year indexEl={indexRef} label="1988年(3歳)">
              <p>神奈川県横浜市に引っ越す</p>
            </Year>

            <Year indexEl={indexRef} label="1989年(4歳)">
              <p>ピアノを習い始める</p>
            </Year>

            <Year indexEl={indexRef} label="1993年(8歳)">
              <p>
                ピアノの練習の休憩時間に「ネコ踏んじゃった」を超高速で弾いてたら、なぜかママに怒られる。
              </p>
            </Year>

            <Year indexEl={indexRef} label="1995年(10歳)">
              <p>ミニ四駆とポケモンばっかりやる。</p>

              <p>
                テレビの歌番組を見るようになり、中でも「シャ乱Q」と「スピッツ」を応援。
              </p>
            </Year>

            <Year indexEl={indexRef} label="1996年(11歳)">
              <p>
                パパが脱サラし音楽バーをはじめるため経理用に「Windows
                95」のPCを買ってきてリビングに置く。
                けどパパはほとんど使わずぁゃぴばっかりインターネットする。
              </p>
            </Year>

            <Year indexEl={indexRef} label="1997年(12歳)">
              <p>
                中学受験を目指し、塾に通いはじめてガリ勉。
                塾での成績は振るわなかったが、偏差値の激高な東京・渋谷の青山学院中等部を志望。
                試験当日に遅刻するも、なぜか奇跡的に合格してしまう。
              </p>
            </Year>

            <Year indexEl={indexRef} label="1998年(13歳)">
              <p>
                同級生が想像を絶するレベルのお金持ちばかりでショックを受ける。
              </p>
              <p>
                アニメにハマり、声優オーディションを受けたら、なぜかママに怒られる。
              </p>
            </Year>

            <Year indexEl={indexRef} label="1999年(14歳)">
              <p>
                当時の彼氏の影響でロックバンド「黒夢」にハマり、同バンドのヴォーカル清春氏の新バンド「SADS」の追っかけをはじめる。
                同じ清春ファンのパンクスの先輩たちからいろいろ際どいことなどを学ぶ。
                黒夢はアルバムごとに音楽性がかなり違ったため、そこから多種多様なジャンルの音楽をディグるようになる。
              </p>

              <p>
                ピアニストとしての才能に限界を感じ、習いに行くのをやめる。自分もかっこいいロックバンドをやりたいと考え、家にあったパパのエレキギター(Grecoの黒いレスポール)で練習をはじめる。
              </p>
            </Year>

            <Year indexEl={indexRef} label="2000年(15歳)">
              <p>
                バンドでヴォーカルをやってくれると言ってたクラスメイトの女の子が居たが、
                ある日突然、まだなにも活動してないのになぜか「あたし、バンドやめる」と言い去り、ショックを受ける。
                するとほかの友達から「あやがギターを弾きながら歌ったほうがいいよ！」と言われ、考えはじめる。
              </p>

              <p>
                毎週日曜日に原宿にヴィジュアル系のコスプレの人たちが大勢集まっているのを度々見に行く。
                そんな大きなブームもあり、バンドをやるなら当然ヴィジュアル系だという感覚になる。
              </p>

              <p>
                不注意でGrecoのギターが盗まれてしまいずっと泣く。
                おばぁちゃんから新しいギターを買ってもらう(Fender Japan「JSG-65
                Jag-Stang」カート・コバーンのモデル)
              </p>

              <p>この頃から「ぁゃぴ」と名乗り始める。</p>
            </Year>

            <Year indexEl={indexRef} label="2001年(16歳)">
              <p>
                エスカレーター式に青山学院高等部に入学。
                スーパー頭いい奴らが大量に入学してきて、落ちこぼれる。
              </p>
              <p>
                YAMAHAのゲームボーイサイズのシーケンサー「QY70」と4トラックレコーダー「MD4」を使って、
                宅録で作詞作曲をはじめる。
              </p>

              <p>
                学校の同級生をメンバーに、３ピースのガールズ・ヴィジュアル系バンド「階段ライト」を結成、
                「ギタァと歌と変な声」を担当。
                以降階段ライトで特別に表記の無い曲はすべてぁゃぴが作詞作曲。
                東京・渋谷の「渋谷区児童会館」で初ライブ。
              </p>

              <ReleaseList>
                <Release>
                  階段ライト
                  2曲入りカセットテープ「プルテウス幼生xp(ぺけぽん)」リリース(内1曲はカヴァー)
                </Release>
              </ReleaseList>
            </Year>

            <Year indexEl={indexRef} label="2002年(17歳)">
              <p>
                機材を買うお金ほしさにセンター街のロッテリア(現バーガーキング)でバイトをはじめるも、
                立ち仕事で疲れるのが嫌すぎて3ヵ月でやめる。
              </p>

              <p>
                座ってできる仕事をさがし、インターネットで詳細不明な仕事の募集に応募すると、
                偶然、ヴィジュアル系バンド界の某大手事務所のウェブ制作のバイトだった。
                未経験の女子高生でありながら、奇跡的に採用される。
                階段ライトのオフィシャルサイトを自分で作っていたのが評価されたらしい。
                以降、必死でウェブプログラミングを勉強。
              </p>

              <ReleaseList>
                <Release>
                  階段ライト
                  5曲入りカセットテープ「空を失う期待感」リリース(内2曲は再録曲)
                </Release>

                <Release>
                  階段ライト 9曲入りCD「企画倒れの遊園地」リリース
                </Release>

                <Release>階段ライト 1曲入りCD「宮益坂の憂鬱」リリース</Release>

                <Release>
                  階段ライト
                  2曲入りCD「ふぁみれど～バナナフィッシュ～」リリース(内1曲はアレンジ違い)
                </Release>
              </ReleaseList>

              <p>
                この頃からギターは赤いテレキャス(Edwards「E-D-TE」Dir en
                greyのDieさんのモデル)
              </p>
            </Year>

            <Year indexEl={indexRef} label="2003年(18歳)">
              <p>
                メンバーの大学受験勉強の専念のため、階段ライト１回目の解散。
              </p>

              <p>
                ぁゃぴはと言うと、大学に行く気はなく、
                学校の進路相談の時間には自分の曲を先生に聴かせて感想をもらって時間を潰すというありさま。
                エスカレーターで比較的にかんたんに付属の大学に行く権利はあるものの、
                先述のバイトでかなり稼いでいることや、この頃パパのお店の経営状況があまりよくなかったため、
                奨学金を自分で背負ってまで行くほどの魅力を見出せず、周囲の反対を押し切る。
              </p>

              <ReleaseList>
                <Release>
                  階段ライト 8曲入りCD「セルリアンタワー破壊衝動」リリース
                </Release>
              </ReleaseList>

              <p>
                ヴィジュアル系バンド「レヴェリー」を結成、ギターを担当。
                全曲を作曲。しかしダメ出しに耐え切れず、たったの3ヵ月で脱退。
                ヴォーカルの羽琉くんがその直後に結成したバンド(「アンティック-珈琲店-」)でバカ売れし、
                その昇り詰めるスピードの凄まじさを目の当たりにする。
              </p>

              <ReleaseList>
                <Release>
                  レヴェリー 3曲入りCD「僕の彼女はグラビアアイドル」リリース
                </Release>
              </ReleaseList>

              <p>「ドゥードゥースクラッチぁゃぴ」名義でソロ活動開始。</p>

              <p>
                宅録の方法をPCのソフトウェアベースに切り替え、
                Steinberg「Cubase」とImage-Line「Fruity Loops」(現FL
                Studio)を使って
                Techno、Tranceなどの電子音楽を取り入れたトラック製作をはじめる。
                そのために安価に性能の良いPCがほしく、弟から教わって自作PCをはじめる。
              </p>

              <ReleaseList>
                <Release>
                  ドゥードゥースクラッチぁゃぴ
                  2曲入りCD「HR304バレーボール応援歌」リリース
                </Release>
                <Release>
                  ドゥードゥースクラッチぁゃぴ
                  6曲入りCD「ネオ渋谷系の不思議チャソ」リリース
                  (内1曲はアレンジ違い)
                </Release>

                <Release>
                  ドゥードゥースクラッチぁゃぴ
                  1曲入りCD「2003autumn→autism...」リリース
                </Release>

                <Release link="https://lnkfi.re/chakumelo">
                  ドゥードゥースクラッチぁゃぴ
                  3曲入りCD「着メロが鳴る前の僕の行動」リリース(内1曲はアレンジ違い)
                </Release>
              </ReleaseList>

              <p>
                満員の渋谷TAKE OFF 7からガラガラの川崎CLUB
                CITTA'まで、多種多様なライブイベントに出演。
              </p>
            </Year>

            <Year indexEl={indexRef} label="2004年(19歳)">
              <p>
                高校卒業。先生達の授業中の声をサンプリングしたTechnoトラックCDを作り、卒業式の当日に同級生達に300円で売る。
              </p>

              <ReleaseList>
                <Release link="https://lnkfi.re/fushigi">
                  ドゥードゥースクラッチぁゃぴ
                  6曲入りCD「ネオ渋谷系の不思議チャソ 2nd
                  Edition」リリース(全曲再録)
                </Release>
                <Release>
                  ドゥードゥースクラッチぁゃぴ
                  1曲入りCD「ぁゃぴ高校卒業記念スペシャルCD」リリース
                </Release>
                <Release>
                  ドゥードゥースクラッチぁゃぴ
                  2曲入りCD「ぁゃぴの究極のォマヶディスク」リリース(内1曲はカヴァー)
                </Release>
                <Release>
                  ドゥードゥースクラッチぁゃぴ
                  1曲入りCD「幻蝕GAL、、コテで逝くべ!?」(カヴァー)
                </Release>
                <Release link="https://lnkfi.re/optima">
                  ドゥードゥースクラッチぁゃぴ 9曲入りCD「optical
                  optima」リリース(内2曲は再録曲)
                </Release>
              </ReleaseList>

              <p>
                この頃、友人バンドからの依頼でRockの曲のTechno
                Remixも作りはじめる。
              </p>
            </Year>

            <Year indexEl={indexRef} label="2005年(20歳)">
              <p>
                1曲にすごく時間をかけて作ってたものの、言うほどよくなくて、残念な気持ちになる。
              </p>

              <p>
                ハタチになったからとかいう謎な理由で、階段ライトをオリジナルメンバーで再結成。
              </p>
            </Year>

            <Year indexEl={indexRef} label="2006年(21歳)">
              <p>
                ヴィジュアル系バンド専門のネットメディアに階段ライトの広告を打ちまくる。少し知名度が上がるも、ヴィジュアル系でヴォーカルがギターを弾きながら歌うスリーピース編成のバンドはほぼ居ないため、ライブを見たことのない大半の人からはドラムが打ち込みだと考えられていた。
              </p>

              <ReleaseList>
                <Release link="https://lnkfi.re/kikaku">
                  階段ライト 9曲入りCD「企画倒れの遊園地
                  リニューアルオープン」リリース (全曲再録)
                </Release>
                <Release link="https://lnkfi.re/cerulean">
                  階段ライト
                  8曲入りCD「セルリアンタワー修復作業」リリース(全曲再録)
                </Release>
              </ReleaseList>

              <ReleaseList>
                <Release link="https://lnkfi.re/sugoi">
                  階段ライト 1曲入りCD「凄い勢い」リリース
                </Release>
                <Release link="https://lnkfi.re/takaitokoro">
                  階段ライト
                  2曲入りCD「高いところ」リリース(内1曲はベースのアスカ作曲)
                </Release>
                <Release link="https://lnkfi.re/okr">
                  階段ライト
                  3曲入りCD「おから」リリース(内1曲はドラムのヤナイ作曲)
                </Release>
              </ReleaseList>

              <p>初の東名阪ツアーなど数々のライブをこなす。</p>

              <p>
                初めてのインストア・トーク・イベントが開催されるも、トークで滑りちらしてショックを受ける。
              </p>

              <p>
                この頃からギターは、シグナルモデリング搭載でピックアップのないデジタルギター
                (LINE6「Variax 600」ヴィンテージ・ホワイト)
              </p>
            </Year>

            <Year indexEl={indexRef} label="2007年(22歳)">
              <p>
                あるネットメディアの企画で階段ライトのレコーディング。
                初めて自宅録音ではない広大なスタジオでのレコーディングで、
                ロックバンド界の超大御所「シーナ&amp;ザ・ロケッツ」の奈良敏博氏と
                「有機生命体」の的場誠也氏が見守る中で進められた。
                しかし、思い通りの音にするためのコミュニケーションの難しさや、
                単純に歌唱力等の実力の至らなさを痛感することになる。
              </p>

              <p>
                また同メディア主催のイベントで恵比寿ガーデンホールにて、
                有名な先輩ヴィジュアル系バンド（「彩冷える」「摩天楼オペラ」）と対バンするも、
                階段ライトへの反響は少なく物販は閑古鳥、これも悔しい結果となる。
              </p>

              <p>夏から秋にかけ全国ツアー。</p>

              <p>
                目黒鹿鳴館にて初ワンマン、満員。
                開演は30分近く押し、やっと客電を落とすも、いきなり「アンコールありがとう！」と登場。
                史上初の「時空の歪み」という謎コンセプトを打ち出す。
              </p>

              <ReleaseList>
                <Release link="https://lnkfi.re/trafficlight">
                  階段ライト 3曲入りCD「信号機の中に一人」リリース
                </Release>
              </ReleaseList>
            </Year>

            <Year indexEl={indexRef} label="2008年(23歳)">
              <p>
                階段ライト、メンバーの脱退により2回目の解散。前年の初ワンマンが事実上のラストライブとなる。
              </p>

              <p>心機一転、ロックバンド「HERE」の追っかけをはじめる。</p>

              <p>その傍ら、一応ソロ活動を開始。</p>

              <ReleaseList>
                <Release link="https://lnkfi.re/imifu">
                  ぁゃぴ (階段ライト) 3曲入りCD「意味不明」リリース
                </Release>
                <Release link="https://lnkfi.re/moontama">
                  ぁゃぴ (階段ライト) 1曲入りCD「ムーンライト多摩川」リリース
                </Release>
              </ReleaseList>

              <p>HEREのライブに行きやすい下北沢で一人暮らしをはじめる。</p>
            </Year>

            <Year indexEl={indexRef} label="2009年(24歳)">
              <p>
                HEREのファンの女の子5人でアイドルグループ「竹の上のポニョ feat.
                え る も」を結成。
                HEREの楽曲をアイドル風にリアレンジし5人で歌うカヴァーアルバムを製作。
              </p>

              <ReleaseList>
                <Release>
                  竹の上のポニョ feat. え る も
                  7曲入りCD「ここにいるよ。」リリース
                </Release>
              </ReleaseList>

              <ReleaseList>
                <Release link="https://lnkfi.re/ddr">
                  ぁゃぴ (階段ライト) 1曲入りCD「D.D.R.
                  だれでも・だいすき・レボリューション」リリース
                </Release>
                <Release link="https://lnkfi.re/hikanaide">
                  ぁゃぴ (階段ライト) 1曲入りCD「ひかないで、後ろ髪」リリース
                </Release>
              </ReleaseList>
            </Year>

            <Year indexEl={indexRef} label="2010年(25歳)">
              <p>
                キャバクラで働いてみるも、全く才能がなく、たった3ヵ月でやめる。
                キャバクラに来ていたお客さんからウェブプログラミングの仕事をもらいながら生活する。
              </p>

              <p>
                的場氏の取り計らいでメンバーが紹介され、３ピース・ロックバンド「3人目の彼女」を結成。
                結成当初はメンバー全員女子で、演奏が上手くなるまではアイドル性で売っていこうと考えた。
                ファンを「ダーリン」と呼び、ライブを「デート」と言うなど、コンセプトも徹底。
                初ライブから満員で評判も上々。
              </p>

              <ReleaseList>
                <Release link="https://lnkfi.re/saigo">
                  3人目の彼女 1曲参加
                  V.A.「聖地☆秋葉原バトルロワイヤル」リリース
                </Release>
              </ReleaseList>

              <p>
                しかし速攻でオリジナルメンバーが脱退。
                以降は凄腕バカテクの男子メンバーを招いて活動していくことになる。
              </p>

              <p>
                この頃からギターはFender Custom
                Shopのrelicのテレキャスター、ヴィンテージホワイト。
              </p>
            </Year>

            <Year indexEl={indexRef} label="2011年(26歳)">
              <p>
                3人目の彼女で3曲入りCDとして発売予定の「チャラいから」のレコーディングに時間をかけすぎて、
                バイトをする時間がなく家賃を3ヵ月滞納。
                家賃保証会社の取り立てが玄関ドアをガンガン叩いてくる毎日を送るが、
                ドアの鍵が開いていても決して向こうから開けてくることはなく、また夜22時になると帰るため、
                法令順守が徹底されていると感じる。
              </p>

              <p>
                「チャラいから」のMVを撮影。おそらくぁゃぴのミスで、
                撮影クルーとバンドメンバーの間で撮影日が１日間違えて伝わっていることが前日に発覚。
                その影響でヘアメイクさんは来られなくなり、メンバーも途中参加などになってしまう。
                そんな中なんとか撮影したものの、やはりヘアメイクさんの居ない中での撮影では
                特に小悪魔アゲハ風に盛りまくっていたぁゃぴの髪はすぐに崩れまくり、
                使えるテイクが極端に少なく、編集作業は難航。
              </p>

              <p>
                一連のグダグダの期間が長すぎて他メンバーは呆れた形で脱退。
                また１からメンバーを探して続けられるほどのパワーが残っていなかったぁゃぴは音楽活動を引退、
                「チャラいから」はMVもCDもお蔵入りとなる。
              </p>

              <p>
                料金未払いで家の電気が止まる。
                その影響かは不明だが、同時期にメインのPCが壊れ、音楽活動関係のデータの多くが消失。
              </p>

              <p>
                大手ゲーム制作会社で、スマホソーシャルゲームのプログラミングの仕事を黙々とこなす。
              </p>
            </Year>

            <Year indexEl={indexRef} label="2012年(27歳)">
              <p>
                会社には、投稿した日報が同じ部署の社員全員にメールで配信されるシステムがあり、
                毎日欠かさずギャル文字で、非常に真面目な内容を投稿していたところ、
                全社的に話題を呼び、ぁゃぴの名が社長・役員のお偉いさんたちにまで知れ渡ることになる。
                子会社の違うビルからわざわざやってきてツーショット写真を希望する人達まで出現。
                しかし一部の人からは、実在を信じられず架空の人物と思われている節もあった。
              </p>

              <p>会社のある渋谷に引っ越す。</p>
            </Year>

            <Year indexEl={indexRef} label="2013年(28歳)">
              <p>
                夏休みをもらい久々に自作PCをして写真をFacebookに友人限定で投稿していたところ、
                友人からブログでの公開を勧められ、
                <a
                  href="https://ameblo.jp/upgrade-ayp/"
                  target="_blank"
                  rel="noreferrer"
                >
                  アメーバブログ「ギャルでもゎかる自作ｐｃ」
                </a>
                を開設。
                開設の翌日に話題沸騰し、はてなブックマークのトップに載るなど、「時の人」となる。
                電車で見知らぬ人が自分の噂を話してるのを聞いたり、
                昔の知り合いと名乗る人から「見ました！」と連絡がきたりする。
                自身を取り巻く急激な変化に気持ちが追いつけず体調を崩し、会社をやめる。
              </p>

              <p>
                その後、
                <a
                  href="https://kai-you.net/article/2215"
                  target="_blank"
                  rel="noreferrer"
                >
                  ウェブメディア「KAI-YOU」にロングインタビュー掲載
                </a>
                、長期に渡りサイト内記事アクセスランキング1位を獲得。
                <a
                  href="https://weekly.ascii.jp/elem/000/002/619/2619655/"
                  target="_blank"
                  rel="noreferrer"
                >
                  週刊アスキー本誌(当時はまだ紙媒体の週刊誌)
                </a>
                やそのムック本にもインタビュー掲載。
                なぜか、会ったこともないシャ乱Qのギターのはたけ氏から電話がかかってきて
                「アスキー見たで、ようわからんけど、なんかがんばってんねんなぁ」と励まされる。
              </p>
            </Year>

            <Year indexEl={indexRef} label="2014年(29歳)">
              <p>
                <a
                  href="http://blog.nicovideo.jp/niconews/ni044958.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  PC販売業者「ドスパラ」提供のニコニコ公式生放送に出演
                </a>
                。初のニコ生にして公式。最後のバトルで大敗しショックを受ける。
              </p>

              <p>
                自作PCブログはマイペースに続けるも、PCは一度作れば普通にしばらく使うため、すぐにネタがなくなる。
              </p>

              <p>
                <a
                  href="https://nodefest.jp/2014/session.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  東京Node学園祭というプログラミングのフェスのようなイベントに登壇し、なぜかトリ
                </a>
                で立ち見が出るほどの盛況。
              </p>
            </Year>

            <Year indexEl={indexRef} label="2015年(30歳)">
              <p>
                有名な出版社から、ギャルがプログラマーになる話、のような自伝本を発売する話が来て、書き始める。
              </p>
            </Year>

            <Year indexEl={indexRef} label="2016年(31歳)">
              <p>
                自伝本は結局ぁゃぴの実力不足でうまく編集さんに頼ることができず、方向性が迷走し、挫折。
                しばらくまともに仕事もしておらずどんどん貧乏になる。
              </p>
            </Year>

            <Year indexEl={indexRef} label="2017年(32歳)">
              <p>
                当時の彼氏に経済的に頼って生活をしていたものの関係が悪化したため、
                仕方なくまたプログラミングの仕事をはじめ、以降2020年まで何社か転々とする。
              </p>
            </Year>

            <Year indexEl={indexRef} label="2018年(33歳)">
              <p>仮想通貨で絶対に勝てる方法を見つけたのに規制される。</p>

              <p>
                ロックバンド「folca」のライブに通いフロアで踊り狂っていると、
                ある日、現場に来ていたロック界の奇行師「アルカラ」のヴォーカル稲村太佑氏から、
                「ぁゃぴお前、才能あると思うねん。音楽やれや。」と、いきなり言われる。
                これをきっかけに、『やっぱり今回の人生は音楽をやるべきだ』と真剣に考え、また曲を作りはじめる。
                ちなみに稲村氏はぁゃぴの曲を１曲も聴いたことがなく、
                ただフロアで騒いでいたのを見ただけで、なんの才能を見出したのかは不明。
              </p>
            </Year>

            <Year indexEl={indexRef} label="2019年(34歳)">
              <p>
                生まれつき口が少し閉じづらく歌唱時の発音にも影響があることや、単純に見た目も改善したいと考え、
                韓国で顎の骨の美容整形手術(「ルフォー＆SSRO」)を受けるも、顔が変わりすぎて誰だかわからなくなってしまう。
                しかし声や身振り手振り等は変わらないためか、意外と友人知人からもすぐに受け入れられる。
              </p>

              <p>
                仮想通貨でこけたことにめげずに投資系のBotの開発を続け、なんか儲かるようになる。
              </p>
            </Year>

            <Year indexEl={indexRef} label="2020年(35歳)">
              <p>
                顎の手術の影響で鼻の形まで変わっていたため、元の鼻の形にするための美容整形手術を韓国で受ける。
              </p>

              <p>もっと創作活動に時間を使いたいと考え、会社をやめる。</p>

              <p>
                会社をやめた当日に、昔のミュージシャン仲間の吉美四季氏から、
                16年前のぁゃぴの曲(
                <a
                  href="https://lnkfi.re/optima"
                  target="_blank"
                  rel="noreferrer"
                >
                  ドゥードゥースクラッチぁゃぴ「残念な雪」
                </a>
                )を最近になって
                <a
                  href="https://twitter.com/ysmsk/status/1255845587414511616?s=20"
                  target="_blank"
                  rel="noreferrer"
                >
                  カヴァーした
                </a>
                と連絡がくる。
                それまでは昔の曲は駄曲ばかりだと思い封印したいような気持ちが強かったが、カヴァーしてもらえるような良い曲もあると思い直す。
              </p>

              <p>
                これまでのほとんどのCDが入手困難で、多くの人に聴いてもらえないという状況を問題視し、
                権利を持っているすべてのCDを、デジタルストアやいわゆる「サブスク」で配信開始。
                これを機に、お蔵入りにしていた3人目の彼女「チャラいから」も、配信で発表することにする。
              </p>

              <ReleaseList>
                <Release link="https://lnkfi.re/charaikara">
                  3人目の彼女 3曲入りCD「チャラいから」配信限定リリース
                </Release>
              </ReleaseList>

              <p>新曲を作り始める。</p>
            </Year>
            <Year indexEl={indexRef} label="2021年(36歳)">
              <p>
                YouTubeにこのページに書いた内容の
                <a
                  href="https://www.youtube.com/playlist?list=PL-wqXZMEOqfQmlDQQNAommOcreLBWsRKG"
                  target="_blank"
                  rel="noreferrer"
                >
                  トークの動画
                </a>
                を上げ始めたものの、黒歴史が多すぎるため、中止。
              </p>
              <p>
                新曲のMVの撮影を始めたものの、構想が壮大すぎたため終わりが見えず、心身ともに疲弊する。
              </p>
            </Year>
            <Year indexEl={indexRef} label="2022年(37歳)">
              <p>
                世間では「
                <a
                  href="https://hiroyuki.coefont.cloud/hiroyuki"
                  target="_blank"
                  rel="noreferrer"
                >
                  おしゃべりひろゆきメーカー
                </a>
                」という、西村博之氏のAI音声(CoeFont)が大流行していたが、発音が不自然になることが多かった。そんな中、ぁゃぴの作るひろゆき音声は、発音の調整の品質が一線を画していると評判を呼び、
                <a
                  href="https://www.youtube.com/@hiroyuki_love"
                  target="_blank"
                  rel="noreferrer"
                >
                  専門のYouTubeチャンネル
                </a>
                を開設。主にお笑いのカバー動画や、「歌わせてみた」動画を中心に投稿。
              </p>
            </Year>
            <Year indexEl={indexRef} label="2023年(38歳)">
              <p>
                AI音声で作りYouTubeに投稿した
                <a
                  href="https://youtu.be/ZQSK8GXz9_8"
                  target="_blank"
                  rel="noreferrer"
                >
                  漫才カバー動画
                </a>
                が、
                <a
                  href="https://x.com/hirox246/status/1676662354925309962?s=20"
                  target="_blank"
                  rel="noreferrer"
                >
                  西村博之氏本人によりTwitterで引用リツイート
                </a>
                され、約100万回再生される。
              </p>
              <p>
                2009年にリリースした「竹の上のポニョ feat.え る
                も」のアルバムについて、発売した枚数が少なすぎたため入手できないという嘆きの声がファンから伝えられたため、
                <a
                  href="https://x.com/ogatakaiki_HERE/status/1634937620789858306?s=20"
                  target="_blank"
                  rel="noreferrer"
                >
                  HEREのヴォーカル尾形回帰氏に改めて許可を頂き
                </a>
                、デジタルストアで配信開始。
              </p>
              <p>
                2021年から撮影しているMVが一向に完成せず、表向きにはAIひろゆきばかりやっているオタクというイメージが定着し危機感を持ったため、MVの進捗については目をつぶり、新曲「役に立つ情報」「カラコン」をデジタルでリリース。
              </p>
              <ReleaseList>
                <Release link="https://ayapi.com/news/post/ayapi-helpful-information/">
                  ayapi
                  2曲入りシングル「役に立つ情報／カラコン」配信限定リリース
                </Release>
              </ReleaseList>
              <p>
                年末、急に思い立ち、
                <a
                  href="https://twitcasting.tv/upgrade_ayp"
                  target="_blank"
                  rel="noreferrer"
                >
                  ライブ配信者としての活動を始めた
                </a>
                ところ、伸び代しかないと実感する。
              </p>
              <p>現在に至る</p>
            </Year>
          </BiographyContent>
        </BiographyWrapper>
      </Wrapper>
    </Layout>
  )
}

export default Profile

export const query = graphql`
  query Profile {
    photo: file(relativePath: { eq: "DSC00313-web.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

const Wrapper = styled.div`
  box-sizing: border-box;
  max-width: 40em;
  margin: 2rem auto;

  @media (${MEDIA_MOBILE}) {
    width: 100%;
    padding: 0 20px;
    padding-top: 0;
  }
`
const ProfileWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;

  @media (${MEDIA_MOBILE}) {
    display: block;
  }
`
const ProfilePhoto = styled.figure`
  display: block;
  flex-basis: 70%;
  margin-right: 5%;

  @media (${MEDIA_MOBILE}) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
`
const ProfileContent = styled.div`
  flex-basis: 25%;

  h3 {
    font-size: 1.6em;
    margin-bottom: 1em;
  }

  p {
    line-height: 1.6;
  }
`
const Warning = styled.p`
  background-color: #ddd;
  text-align: center;
  margin-bottom: 1rem;
  padding: 2px;
`
const BiographyWrapper = styled.div`
  position: relative;
  line-height: 1.6;
`
const BiographyIndex = styled.div`
  position: sticky;
  height: 100%;
  top: 70px;
  left: 0;
  float: left;
  list-style-type: none;
  line-height: 1.5;
  font-size: 0.7rem;
`
const BiographyContent = styled.div`
  margin-left: 3rem;

  ${typography.toString()}

  a {
    text-decoration: underline dotted;
  }
`
const ReleaseList = styled.div`
  margin-bottom: 2rem;
`
const ReleaseItem = styled.div`
  margin-left: 1rem;
  text-indent: -1em;
  font-size: smaller;

  > a {
    display: inline-block;
    margin-left: 0.5em;
    padding: 0 0.1em;
    text-indent: 0;
    text-decoration: none;
    background-color: #000;
    color: #fff;

    &:hover {
      color: #ccc;
      background-color: #333;
    }
  }
`
